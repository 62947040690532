/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useState } from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import NotificationsIcon from "@mui/icons-material/Notifications";
import EmailIcon from "@mui/icons-material/Email";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";

const PROFILE_MENU_CLASS_NAMES =
  "block px-4 py-2 text-sm text-gray-700 hover:bg-slate-100";

export default function NavBar() {
  const [username, setUsername] = useState(localStorage.getItem("username"));

  document.addEventListener("authenticated", ({ detail }) => {
    setUsername(detail.username);
  });

  return (
    <div className="shadow-xl md:px-8 w-full md:flex md:flex place-items-center py-3">
      <div className="text-slate-500 grow container">
        <GridViewIcon className="" />
        <span>Dashboard</span>
      </div>
      <div className="flex flex place-items-center">
        {/* <EmailIcon className="color-dark mr-2" /> */}
        <a className="relative" href="http://kf.papvire.com" target="_blank">
          <ImportantDevicesIcon className="color-dark" />
        </a>
        <div className="md:ml-4 h-full flex flex justify-center items-stretch">
          <div className="self-center mr-2 bg-white w-8 h-8 rounded-full flex justify-start items-center text-center container mx-auto">
            <img src="/logo-rond.jpg" alt="" className="w-8 h-8" />
          </div>
          <span className="self-center">{username}</span>
        </div>
      </div>
    </div>
    // <Disclosure as="nav" className="shadow-xl fixed w-full">
    //   <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
    //     <div className="relative flex h-16 items-center justify-end">
    //       <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
    //         <Menu as="div" className="relative ml-3">
    //           <div>
    //             <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
    //               <span className="sr-only">Ouvrir le menu</span>
    //               <img className="h-8 w-8 rounded-full" src="/logo-rond.jpg" alt="" />
    //             </Menu.Button>
    //           </div>
    //           <Transition
    //             as={Fragment}
    //             enter="transition ease-out duration-100"
    //             enterFrom="transform opacity-0 scale-95"
    //             enterTo="transform opacity-100 scale-100"
    //             leave="transition ease-in duration-75"
    //             leaveFrom="transform opacity-100 scale-100"
    //             leaveTo="transform opacity-0 scale-95"
    //           >
    //             <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
    //               <Menu.Item className={PROFILE_MENU_CLASS_NAMES}>
    //                 <Link className={PROFILE_MENU_CLASS_NAMES} to={PROFILE_URL}>
    //                   Profile
    //                 </Link>
    //               </Menu.Item>
    //               <Menu.Item className={PROFILE_MENU_CLASS_NAMES}>
    //                 <span className={PROFILE_MENU_CLASS_NAMES} onClick={() => logout()}>
    //                   Sign out
    //                 </span>
    //               </Menu.Item>
    //             </Menu.Items>
    //           </Transition>
    //         </Menu>
    //       </div>
    //     </div>
    //   </div>
    // </Disclosure>
  );
}
