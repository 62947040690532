/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  LayersControl,
  FeatureGroup,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import Control from "react-leaflet-custom-control";
import country from "../Core/shapes/departement.json";
import bruteData from "../Core/shapes/brute_data.json";
import {
  MAP_VIEWS,
  LAYER_CONTROLS,
  MAP_FEATURE_TYPES,
  Layers,
  API_URL,
} from "../../constants";
import ViewController from "./ViewController";
import MarkerModel from "../../models/MarkerModel";
import ZoomControlWithReset from "./ZoomWithReset";

/* Note: DO NOT DELETE THIS COMMENT
When using local copy of leaflet css markers are not displayed properly
Following code is meant to solve the issue.
See https://github.com/PaulLeCam/react-leaflet/issues/453#issuecomment-410450387
*/
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const DEFAULT_MARKER_PER_CONTROL_LAYER = [
  {
    layerControlId: 1,
    markers: [],
  },
  {
    layerControlId: 2,
    markers: [],
  },
];

function PopupComponent(props) {
  const { marker, layerControl } = props;
  const map = useMap();
  return (
    <Popup>
      <div
        style={{
          display: "grid",
          gap: "3px",
        }}
      >
        <a
          role="button"
          onClick={() =>
            map.flyTo([marker.localisation.latitude, marker.localisation.longitude], 18)
          }
        >
          Zoomer
        </a>
        <div>Nom du site : {marker.label.siteName} </div>
        <div>Bénéficiaire : {marker.label.recipient} </div>
        <div>Montant estimé : {marker.label.estimatedAmount} </div>
        <div>Montant dépensé : {marker.label.spentAmount} </div>
        {layerControl.label === "Amenagement" && (
          <>
            Télécharger la{" "}
            <a
              href={`${API_URL}amenagements/${marker.label.siteName}/fiche/`}
              target="_blank"
            >
              fiche
            </a>
          </>
        )}
      </div>
    </Popup>
  );
}

function Mapping() {
  const bounds = L.geoJSON(country).getBounds();
  const [view, setView] = useState(MAP_VIEWS[0]);
  const [tileKey, setTileKey] = useState(MAP_VIEWS[0].id);
  const [apiCalling, setApiCalling] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [markerPerControlLayer, setMarkerPerControlLayer] = useState(
    DEFAULT_MARKER_PER_CONTROL_LAYER,
  );
  const [map, setMap] = useState();

  const changeView = (viewToSet) => {
    setView(viewToSet);
    setTileKey(viewToSet.id);
  };

  const didMount1 = useRef(false);
  const didMount2 = useRef(false);

  // useEffect(() => {
  //   if (didMount1.current) {
  //     setApiCalling(true);
  //     setHasError(false);
  //     const tempMarkerSet = [];
  //     LAYER_CONTROLS.every(async (layerControl) => {
  //       const params = {
  //         endPoint: layerControl.endPoint,
  //         isArrayTransformer: true,
  //         transformer: MarkerModel.fromJson,
  //       }
  //       const requestResponse = await transformApiResponse(params);
  //       if (requestResponse instanceof CustomError) {
  //         setApiCalling(false);
  //         setHasError(true);
  //         return false;
  //       }
  //       const tempObj = { layerControlId: layerControl.id, markers: requestResponse };
  //       tempMarkerSet.push(tempObj);
  //     });
  //     console.log("TEMP MARKER SET ", tempMarkerSet);
  //     setMarkerPerControlLayer(tempMarkerSet);
  //     setApiCalling(false);
  //     setHasError(false);
  //   } else {
  //     didMount1.current = true
  //   }
  // }, []);

  useEffect(() => {
    const tempMarkerSet = [
      { layerControlId: "1", markers: [] },
      { layerControlId: "2", markers: [] },
      { layerControlId: "3", markers: [] },
      { layerControlId: "4", markers: [] },
    ];
    const allData = bruteData.features;
    try {
      setApiCalling(true);
      if (allData) {
        allData.forEach((feature) => {
          const { properties, geometry } = feature;
          const label = properties.Libellé;
          const featureType = MAP_FEATURE_TYPES.find((element) => {
            const { correspondingTypes } = element;
            const tempElmt = correspondingTypes.find(
              (elmt) =>
                elmt.toLocaleLowerCase().includes(label.toLocaleLowerCase()) ||
                label.toLocaleLowerCase().includes(elmt.toLocaleLowerCase()),
            );
            if (tempElmt) return true;
            return false;
          });
          if (featureType) {
            const markerSet = tempMarkerSet.find(
              (element) => element.layerControlId === featureType.id,
            );
            if (markerSet) {
              markerSet.markers.push(MarkerModel.fromJson({ properties, geometry }));
            }
          }
        });
        setMarkerPerControlLayer(tempMarkerSet);
      }
      setApiCalling(false);
    } catch (e) {
      setApiCalling(false);
      setHasError(true);
    }
  }, []);

  return (
    <div className=" w-full h-full py-8">
      <div className="border-4 border-white w-full h-full min-h-max container mx-auto rounded-3xl">
        <MapContainer bounds={bounds} zoomControl={false}>
          <ZoomControlWithReset bounds={bounds} />
          <LayersControl position="topright">
            {Layers.map((layer) => (
              <LayersControl.Overlay key={layer.name} name={layer.name}>
                {layer.name === "Départements d'intervention" ? (
                  <FeatureGroup>
                    {layer.layer.features.map((feature) => {
                      let dataJson = feature;
                      return (
                        <FeatureGroup>
                          {<GeoJSON data={dataJson} style={{ color: "green" }} />}
                          <Popup>
                            <div
                              style={{
                                gap: "3px",
                              }}
                            >
                              <div className="d-flex justify-content-center font-bold">
                                <div> {dataJson.properties.Nom} </div>
                              </div>
                              Télécharger la{" "}
                              <a
                                href={`${API_URL}departements/${dataJson.properties.Nom.toUpperCase()}/fiche/`}
                                target="_blank"
                              >
                                fiche
                              </a>
                            </div>
                          </Popup>
                        </FeatureGroup>
                      );
                    })}
                  </FeatureGroup>
                ) : (
                  <GeoJSON data={layer.layer} style={layer.style || {}} />
                )}
              </LayersControl.Overlay>
            ))}
            {!apiCalling &&
              !hasError &&
              LAYER_CONTROLS.map((layerControl) => {
                let markers = [];
                const layerControlKey = `${layerControl.label}-${layerControl.id}`;
                const correspondingMarkers = markerPerControlLayer.find(
                  (element) => element.layerControlId === layerControl.id,
                );
                if (correspondingMarkers) {
                  markers = correspondingMarkers.markers;
                }
                return (
                  <LayersControl.Overlay
                    key={layerControlKey}
                    name={layerControl.label}
                  >
                    <FeatureGroup>
                      {markers
                        ? markers.map((marker, index) => {
                            const markerkey = `${layerControl.label}-${index}`;
                            return (
                              <Marker
                                position={[
                                  marker.localisation.latitude,
                                  marker.localisation.longitude,
                                ]}
                                icon={layerControl.iconMarker}
                                key={markerkey}
                              >
                                <PopupComponent
                                  marker={marker}
                                  layerControl={layerControl}
                                />
                              </Marker>
                            );
                          })
                        : null}
                    </FeatureGroup>
                  </LayersControl.Overlay>
                );
              })}
          </LayersControl>
          <Control prepend position="topright">
            <ViewController selectedMapView={view} onMapViewChanged={changeView} />
          </Control>
          <TileLayer key={tileKey} {...view.tileLayerInfos} />
        </MapContainer>
      </div>
    </div>
  );
}

export default Mapping;
