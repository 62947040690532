import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// Conponents
import HomePage from "./views/HomeView";
import LoginPage from "./views/LoginView";
import AboutPage from "./views/AboutView";
import ProfileView from "./components/Profile/ProfileView";
import SettingsView from "./components/Settings/SettingsView";
import CartothequeView from "./components/Cartotheque/CartothequeView";
import DataTableView from "./components/DataTable/DataTableView";
import Dashboard from "./components/Dashboard/Dashboard";
import NotFoundView from "./components/NotFound/NotFoundView";
import NotFoundOutletView from "./components/NotFound/NotFoundOutletView";
import "./i18n";
import ReporListView from "./components/Report/ReportListView";
import Mapping from "./components/Mapping/Mapping";
import Actor from "./components/Actors/Actor";
import ChartRenderer from "./components/Dashboard/ChartsRenderer";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Navigate to="login" />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="*" element={<NotFoundView />} />
      <Route path="app" element={<App />}>
        <Route path="dashboard" element={<Dashboard />}>
          <Route path="a" element={<ChartRenderer composante="A" />} />
          <Route path="b" element={<ChartRenderer composante="B" />} />
        </Route>
        <Route path="cartotheque" element={<CartothequeView />} />
        <Route path="datatable" element={<DataTableView />} />
        <Route path="profile" element={<ProfileView />} />
        <Route path="settings" element={<SettingsView />} />
        <Route path="report" element={<ReporListView />} />
        <Route path="mapping" element={<Mapping />} />
        <Route path="contributor" element={<Actor />} />
        <Route path="*" element={<NotFoundOutletView />} />
      </Route>
      <Route path="about" element={<AboutPage />} />
    </Routes>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
