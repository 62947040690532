export interface ReportConstructorInterface {
  intitule: string;

  fichier: string;

  auteur: string;

  resume: string;

  createdAt: string;
}

export default class Report {
  intitule: string;

  fichier: string;

  auteur: string;

  resume: string;

  created_at: string;

  constructor({
    intitule,
    fichier,
    auteur,
    resume,
    createdAt,
  }: ReportConstructorInterface) {
    this.intitule = intitule;
    this.fichier = fichier;
    this.auteur = auteur;
    this.resume = resume;
    this.created_at = createdAt;
  }

  static fromJson(json: any) {
    return new Report({ ...json, createdAt: json.created_at });
  }
}
