export interface AreaMapConstructorInterface {
  id: string;

  nom: string;

  image: string;

  description: string;
}

export default class AreaMap {
  id: string;

  nom: string;

  image: string;

  description: string;

  constructor({ nom, id, image, description }: AreaMapConstructorInterface) {
    this.description = description;
    this.id = id;
    this.image = image;
    this.nom = nom;
  }
}
