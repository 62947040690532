import React, { Fragment, useRef, useState, useEffect } from "react";
import "./CartothequeStyle.css";
import { PhotographIcon } from "@heroicons/react/solid";
import { Dialog, Transition } from "@headlessui/react";
// import { useTranslation } from 'react-i18next';

interface ComponentProps {
  propsOpen: boolean;
  onModalClosed: () => void;
}

const disabledButtonClass =
  "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-slate-300 text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm";
const enabledButtonClass =
  "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm";

function MapForm(props: ComponentProps) {
  // const { t } = useTranslation();
  const { propsOpen, onModalClosed } = props;
  const [open, setOpen] = useState(propsOpen);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile] = useState<any>(undefined);
  useEffect(() => {
    setOpen(propsOpen);
  }, [propsOpen]);
  useEffect(() => {
    setOpen(propsOpen);
  }, [propsOpen]);
  const cancelButtonRef = useRef(null);

  const onThisClosed = () => {
    setOpen(false);
    onModalClosed();
  };

  const checkFormValidity = () => {
    if (name !== "" && description !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onNameChanged = (event: any) => {
    setName(event.target.value);
    checkFormValidity();
  };

  const onDescriptionChanged = (event: any) => {
    setDescription(event.target.value);
    checkFormValidity();
  };

  const onFileChanged = (event: any) => {
    setSelectedFile(event.target.file);
    checkFormValidity();
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    setDisabled(true);

    setTimeout(() => {
      setLoading(false);
      setDisabled(false);
      onThisClosed();
    }, 1000);
  };

  // const buttonSubmitContainer = (): any => {
  //   let valueToReturn = "Enregistrer";
  //   if (loading) {
  //     valueToReturn = "Traitement en cours..."
  //   }
  //   return valueToReturn
  // }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={onThisClosed}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Modifier la carte
                    </Dialog.Title>
                    <div className="mt-2">
                      <form>
                        <div className="grid grid-cols-3 gap-3">
                          <div className="col-start-auto block">
                            <span className="text-sm font-medium text-gray-700">
                              Photo de la carte
                            </span>
                            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                              <div className="space-y-1 text-center">
                                <PhotographIcon className="h-6 w-6 text-gray-500" />
                                <div className="flex text-sm text-gray-600">
                                  <label
                                    htmlFor="file-upload"
                                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                  >
                                    <span>Modifier la photo</span>
                                    <input
                                      id="file-upload"
                                      name="image"
                                      type="file"
                                      className="sr-only"
                                      value={selectedFile}
                                      onChange={onFileChanged}
                                    />
                                  </label>
                                </div>
                                <p className="text-xs text-gray-500">PNG, JPG, GIF</p>
                                <span>Votre fichier: {selectedFile}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-3 block">
                            <label
                              htmlFor="map-name"
                              className="text-sm font-medium text-gray-700"
                            >
                              Nom de la carte
                              <input
                                type="text"
                                name="name"
                                id="map-name"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                placeholder="nom de la carte"
                                value={name}
                                onChange={onNameChanged}
                              />
                            </label>
                            <p className="mt-2 text-sm text-gray-500">
                              Le nom de la carte
                            </p>
                          </div>
                          <div className="col-span-3 block">
                            <label
                              htmlFor="map-description"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Description
                              <div className="mt-1">
                                <textarea
                                  id="map-description"
                                  name="description"
                                  rows={3}
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                                  placeholder="Description de la carte"
                                  value={description}
                                  onChange={onDescriptionChanged}
                                />
                              </div>
                            </label>
                            <p className="mt-2 text-sm text-gray-500">
                              Une description de la carte
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  disabled={disabled}
                  type="button"
                  className={disabled ? disabledButtonClass : enabledButtonClass}
                  onClick={onSubmit}
                >
                  {loading ? "Traitement en cours" : "Enregistrer"}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => onThisClosed()}
                  ref={cancelButtonRef}
                >
                  Annuler
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default MapForm;
