interface DataToShow {
  siteName: string;
  recipient: string;
  spentAmount: string;
  estimatedAmount: string;
}
export interface MarkerConstructorInterface {
  localisation: any;

  image: string;

  label: DataToShow;
}

export default class MarkerModel {
  localisation: any;

  image: string;

  label: DataToShow;

  constructor({ localisation, image, label }: MarkerConstructorInterface) {
    this.localisation = localisation;
    this.image = image;
    this.label = label;
  }

  static fromJson(json: any) {
    return new MarkerModel({
      localisation: {
        longitude: json.geometry.coordinates[0],
        latitude: json.geometry.coordinates[1],
      },
      image: "",
      label: {
        siteName: json.properties.Site,
        recipient: json.properties.effbenef,
        estimatedAmount: json.properties.Montprev,
        spentAmount: json.properties.MontDec,
      },
    });
  }
}
