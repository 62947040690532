/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
// eslint-disable-next-line import/prefer-default-export
// export const API_URL = "https://a71b14a2c907.eu.ngrok.io/"
import React from "react";
import {
  HomeIcon,
  MapIcon,
  DocumentTextIcon,
  GlobeAltIcon,
  TableIcon,
  UserGroupIcon,
  ChartBarIcon,
  LibraryIcon,
} from "@heroicons/react/solid";
import L from "leaflet";
import departements from "./components/Core/shapes/departement.json";
import departementsIntervention from "./components/Core/shapes/departement_intervention.json";
import benin from "./components/Core/shapes/benin.json";
import communes from "./components/Core/shapes/communesIntervention.json";
import poles from "./components/Core/shapes/poles.json";
import Report from "./models/ReportModel";

export const { REACT_APP_API_URL: API_URL } = process.env;
// export const { REACT_APP_API_URL: API_URL } = {
//   REACT_APP_API_URL: "http://135.181.188.163:8000/",
// };

export const DEFAULT_PAGE_SIZE = 0;
export const APP_URL = "/app";
export const DASHBOARD_URL = `${APP_URL}/dashboard`;
export const HOMEPAGE_URL = `${APP_URL}/homepage`;
export const CARTO_URL = `${APP_URL}/cartotheque`;
export const MAPPING_URL = `${APP_URL}/mapping`;
export const REPORT_URL = `${APP_URL}/report`;
export const DATA_URL = `${APP_URL}/datatable`;
export const CONTRIBUTOR_URL = `${APP_URL}/contributor`;
export const PROFILE_URL = `${APP_URL}/profile`;
export const SETTINGS = `${APP_URL}/settings`;

export const TITLECLASS =
  "capitalize tracking-wide text-blue-400 font-extrabold text-3xl indent-1.5";

export const SIDEBARELEMENTS = [
  {
    url: DASHBOARD_URL,
    title: "sidebar_titles.home",
    leading: () => <HomeIcon className="h-6 w-6 p-1" />,
    centeredLeading: () => <HomeIcon className="h-6 w-6 p-1 m-auto" />,
    subElements: [
      {
        url: `${DASHBOARD_URL}/a`,
        title: "Composante A",
        leading: () => <LibraryIcon className="h-6 w-6 p-1" />,
        centeredLeading: () => <LibraryIcon className="h-6 w-6 p-1 m-auto" />,
      },
      {
        url: `${DASHBOARD_URL}/b`,
        title: "Composante B",
        leading: () => <ChartBarIcon className="h-6 w-6 p-1" />,
        centeredLeading: () => <ChartBarIcon className="h-6 w-6 p-1 m-auto" />,
      },
    ],
  },
  {
    url: MAPPING_URL,
    title: "sidebar_titles.mapping",
    leading: () => <GlobeAltIcon className="h-6 w-6 p-1" />,
    centeredLeading: () => <GlobeAltIcon className="h-6 w-6 p-1 m-auto" />,
  },
  {
    url: REPORT_URL,
    title: "sidebar_titles.report",
    leading: () => <DocumentTextIcon className="h-6 w-6 p-1" />,
    centeredLeading: () => <DocumentTextIcon className="h-6 w-6 p-1 m-auto" />,
  },
  {
    url: CARTO_URL,
    title: "sidebar_titles.mapLibrary",
    leading: () => <MapIcon className="h-6 w-6 p-1" />,
    centeredLeading: () => <MapIcon className="h-6 w-6 p-1 m-auto" />,
  },
  {
    url: DATA_URL,
    title: "sidebar_titles.dataTable",
    leading: () => <TableIcon className="h-6 w-6 p-1" />,
    centeredLeading: () => <TableIcon className="h-6 w-6 p-1 m-auto" />,
  },
  {
    url: CONTRIBUTOR_URL,
    title: "sidebar_titles.contributor",
    leading: () => <UserGroupIcon className="h-6 w-6 p-1" />,
    centeredLeading: () => <UserGroupIcon className="h-6 w-6 p-1" />,
  },
];

export interface MapViewInterface {
  id: string;
  illustration: string;
  viewName: string;
  tileLayerInfos: any;
}

const SATELITE_VIEW = {
  attribution:
    "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
  url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
};

const DEFAULT_VIEW = {
  attribution:
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
};

export const MAP_VIEWS: MapViewInterface[] = [
  {
    id: "1",
    illustration: "/street.jpg",
    viewName: "Vue par défaut",
    tileLayerInfos: DEFAULT_VIEW,
  },
  {
    id: "2",
    illustration: "/sat.jpg",
    viewName: "Vue satelitaire",
    tileLayerInfos: SATELITE_VIEW,
  },
];

const AMENAGEMENT_BAR_COLOR = "rgba(226, 59, 8, 0.5)";
const AMENAGEMENT_ENTREPRISE_BAR_COLOR = "rgba(25, 40, 200, 0.5)";
const AMENAGEMENT_AVAL_BAR_COLOR = "rgba(50, 70, 50, 0.5)";
const PISTE_BAR_COLOR = "rgba(96, 18, 241, 0.5)";
const RETENUE_BAR_COLOR = "#1d139782";
const CORN_YELLOW = "#fed809";
const GREEN_RICE = "#74813b";

const colors = [
  GREEN_RICE,
  CORN_YELLOW,
  AMENAGEMENT_AVAL_BAR_COLOR,
  PISTE_BAR_COLOR,
  RETENUE_BAR_COLOR,
  AMENAGEMENT_AVAL_BAR_COLOR,
  AMENAGEMENT_BAR_COLOR,
  AMENAGEMENT_ENTREPRISE_BAR_COLOR,
];

// **************************************************************************functions for Bar Chart data transformation
const chartDataFromJson = (
  dataToGet: string,
  jsonData?: any,
  attributes?: any,
  backgroundColors: string[] = [AMENAGEMENT_BAR_COLOR],
  chartLabels?: string[],
) => {
  const labels: string[] = [];
  let yAxisdata: number[] = [];

  const datasets: any = attributes?.map((attribute: any, idx: any) => {
    let dataset: any = {
      label: chartLabels ? chartLabels[idx] : dataToGet,
      data: [],
      backgroundColor: backgroundColors[idx],
    };
    if (typeof attribute !== "string") {
      const [att, type] = attribute;
      dataset.type = type;
      if (type == "line") {
        dataset = {
          ...dataset,
          borderColor: "rgba(0, 0, 0, 0)",
          pointBorderColor: backgroundColors[idx],
          pointStyle: "line",
          datalabels: { display: false },
          pointRadius: 52,
          pointBorderWidth: 5,
          pointHoverRadius: 52,
          pointHoverBorderWidth: 5,
        };
      }
    }
    return dataset;
  });

  if (jsonData && attributes) {
    jsonData.forEach((element: any) => {
      labels.push(element[dataToGet]);
      attributes?.forEach((attribute: any, idx: any) => {
        if (typeof attribute !== "string") {
          const [att, type] = attribute;
          attribute = att;
        }
        const modulus = element[attribute] % 1;
        const number = modulus ? element[attribute].toFixed(2) : element[attribute];

        datasets[idx].data.push(number);
      });
    });
  }
  return {
    labels,
    datasets,
  };
};

const intrantDataSetFromJson = (dataToGet: string, jsonData: any, unit: string) => {
  const labels = new Set();
  let yAxisdata: number[] = [];
  let dataset: any = {};
  let count = 0;
  jsonData.forEach((element: any) => {
    labels.add(element[dataToGet]);
    const modulus = element.quantite % 1;
    const number = modulus ? element.quantite.toFixed(2) : element.quantite;

    const existing = dataset[element.name];
    if (existing) {
      existing.data.push(number);
    } else {
      dataset[element.name] = {
        label: `${element.name} (${unit})`,
        data: [number],
        backgroundColor: colors[count],
      };
      count++;
    }
  });

  const labelList = Array.from(labels);
  const datasets = Object.values(dataset);

  return {
    labels: labelList,
    datasets,
  };
};

// const baseLevels = [
//   { id: 0, metaData: { label: "departement", chartName: "départements" } },
//   { id: 0, metaData: { label: "commune", chartName: "communes" } },
//   { id: 0, metaData: { label: "pole", chartName: "poles" } }
// ]

export const COMP_A_CHARTS = [
  {
    primaryChartName: "Amenagements en HIMO (en ha)",
    legendStart: "",
    params: { type_amen: "himo" },
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "amenagements/stats/by_departement/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "departement",
              jsonData,
              [
                ["sup_amenage_prevue", "line"],
                ["sup_amenage", "bar"],
              ],
              [GREEN_RICE, CORN_YELLOW],
              ["Superficie prévue", "Superficie aménagée"],
            ),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "amenagements/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "commune",
              jsonData,
              [
                ["sup_amenage_prevue", "line"],
                ["sup_amenage", "bar"],
              ],
              [GREEN_RICE, CORN_YELLOW],
              ["Superficie prévue", "Superficie aménagée"],
            ),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "amenagements/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "pole",
              jsonData,
              [
                ["sup_amenage_prevue", "line"],
                ["sup_amenage", "bar"],
              ],
              [GREEN_RICE, CORN_YELLOW],
              ["Superficie prévue", "Superficie aménagée"],
            ),
        },
      },
    ],
  },
  {
    primaryChartName: "Amenagements HIMO - Entreprise (en ha)",
    legendStart: "",
    params: { type_amen: "himo_entreprise" },
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "amenagements/stats/by_departement/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "departement",
              jsonData,
              [
                ["sup_amenage_prevue", "line"],
                ["sup_amenage", "bar"],
              ],
              [GREEN_RICE, CORN_YELLOW],
              ["Superficie prévue", "Superficie aménagée"],
            ),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "amenagements/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "commune",
              jsonData,
              [
                ["sup_amenage_prevue", "line"],
                ["sup_amenage", "bar"],
              ],
              [GREEN_RICE, CORN_YELLOW],
              ["Superficie prévue", "Superficie aménagée"],
            ),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "amenagements/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "pole",
              jsonData,
              [
                ["sup_amenage_prevue", "line"],
                ["sup_amenage", "bar"],
              ],
              [GREEN_RICE, CORN_YELLOW],
              ["Superficie prévue", "Superficie aménagée"],
            ),
        },
      },
    ],
  },
  {
    primaryChartName: "Amenagements en Entreprise exclusif (en ha)",
    legendStart: "",
    params: { type_amen: "entreprise" },
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "amenagements/stats/by_departement/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "departement",
              jsonData,
              [
                ["sup_amenage_prevue", "line"],
                ["sup_amenage", "bar"],
              ],
              [GREEN_RICE, CORN_YELLOW],
              ["Superficie prévue", "Superficie aménagée"],
            ),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "amenagements/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "commune",
              jsonData,
              [
                ["sup_amenage_prevue", "line"],
                ["sup_amenage", "bar"],
              ],
              [GREEN_RICE, CORN_YELLOW],
              ["Superficie prévue", "Superficie aménagée"],
            ),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "amenagements/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "pole",
              jsonData,
              [
                ["sup_amenage_prevue", "line"],
                ["sup_amenage", "bar"],
              ],
              [GREEN_RICE, CORN_YELLOW],
              ["Superficie prévue", "Superficie aménagée"],
            ),
        },
      },
    ],
  },
  {
    primaryChartName: "Amenagements en aval de retenue d'eau (en ha)",
    legendStart: "",
    params: { type_amen: "retenue" },
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "amenagements/stats/by_departement/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "departement",
              jsonData,
              [
                ["sup_amenage_prevue", "line"],
                ["sup_amenage", "bar"],
              ],
              [GREEN_RICE, CORN_YELLOW],
              ["Superficie prévue", "Superficie aménagée"],
            ),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "amenagements/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "commune",
              jsonData,
              [
                ["sup_amenage_prevue", "line"],
                ["sup_amenage", "bar"],
              ],
              [GREEN_RICE, CORN_YELLOW],
              ["Superficie prévue", "Superficie aménagée"],
            ),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "amenagements/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "pole",
              jsonData,
              [
                ["sup_amenage_prevue", "line"],
                ["sup_amenage", "bar"],
              ],
              [GREEN_RICE, CORN_YELLOW],
              ["Superficie prévue", "Superficie aménagée"],
            ),
        },
      },
    ],
  },
  {
    primaryChartName: "Nombre de Forages",
    legendStart: "",
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "forages/stats/by_departement/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "departement",
              jsonData,
              ["nombre_sur_site"],
              ["#bbb951"],
            ),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "forages/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson("commune", jsonData, ["nombre_sur_site"], ["#fce79c"]),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "forages/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson("pole", jsonData, ["nombre_sur_site"], ["#ec67a1"]),
        },
      },
    ],
  },
  {
    primaryChartName: "Pistes (en km)",
    legendStart: "Longueur réhabilitée par ",
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "pistes/stats/by_departement",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "departement",
              jsonData,
              ["long_rehabilitatee"],
              ["#e0dcb8"],
            ),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "pistes/stats/by_commune",
          transformer: (jsonData: any) =>
            chartDataFromJson("commune", jsonData, ["long_rehabilitatee"], ["#e0dcb8"]),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "pistes/stats/by_pole",
          transformer: (jsonData: any) =>
            chartDataFromJson("pole", jsonData, ["long_rehabilitatee"], ["#e0dcb8"]),
        },
      },
    ],
  },
  {
    primaryChartName: "Nombre de Retenues d'eau",
    legendStart: "Capacité réhabilitée par ",
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "retenues/stats/by_departement",
          transformer: (jsonData: any) =>
            chartDataFromJson("departement", jsonData, ["pk"], ["#a1c5cc"]),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "retenues/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson("commune", jsonData, ["pk"], ["#a1c5cc"]),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "retenues/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson("pole", jsonData, ["pk"], ["#a1c5cc"]),
        },
      },
    ],
  },
  {
    primaryChartName: "Nombre de Magasins",
    legendStart: "Total par ",
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "magasins/stats/by_departement",
          transformer: (jsonData: any) =>
            chartDataFromJson("departement", jsonData, ["pk"], ["#b19cae"]),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "magasins/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson("commune", jsonData, ["pk"], ["#b19cae"]),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "magasins/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson("pole", jsonData, ["pk"], ["#b19cae"]),
        },
      },
    ],
  },
  {
    primaryChartName: "Nombre de Boutiques",
    legendStart: "Total par ",
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "boutiques/stats/by_departement",
          transformer: (jsonData: any) =>
            chartDataFromJson("departement", jsonData, ["pk"], ["#97c26a"]),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "boutiques/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson("commune", jsonData, ["pk"], ["#97c26a"]),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "boutiques/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson("pole", jsonData, ["pk"], ["#97c26a"]),
        },
      },
    ],
  },
  {
    primaryChartName: "Nombre d'ESOP",
    legendStart: "Total par ",
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "esop/stats/by_departement",
          transformer: (jsonData: any) =>
            chartDataFromJson("departement", jsonData, ["pk"], ["#f6bb01"]),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "esop/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson("commune", jsonData, ["pk"], ["#f6bb01"]),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "esop/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson("pole", jsonData, ["pk"], ["#f6bb01"]),
        },
      },
    ],
  },
  {
    primaryChartName: "Nombre de jeunes installés",
    legendStart: "Total par ",
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "installations/stats/by_departement",
          transformer: (jsonData: any) =>
            chartDataFromJson("departement", jsonData, ["pk"], ["#1d6bab"]),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "installations/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson("commune", jsonData, ["pk"], ["#1d6bab"]),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "installations/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson("pole", jsonData, ["pk"], ["#1d6bab"]),
        },
      },
    ],
  },
];

export const INDICATORS_CHARTS = {
  legendStart: "",
  levels: [
    {
      level: 0,
      metaData: {
        label: "departement",
        chartName: "départements",
        endPoint: "indicator_values/stats/by_departement/",
        transformer: (jsonData: any) =>
          chartDataFromJson("departement", jsonData, ["real", "woman_real"], colors, [
            "Total",
            "Femmes",
          ]),
      },
    },
    {
      level: 1,
      metaData: {
        label: "commune",
        chartName: "communes",
        endPoint: "indicator_values/stats/by_commune/",
        transformer: (jsonData: any) =>
          chartDataFromJson("commune", jsonData, ["real", "woman_real"], colors, [
            "Total",
            "Femmes",
          ]),
      },
    },
    {
      level: 0,
      metaData: {
        label: "pole",
        chartName: "poles",
        endPoint: "indicator_values/stats/by_pole/",
        transformer: (jsonData: any) =>
          chartDataFromJson("pole", jsonData, ["real", "woman_real"], colors, [
            "Total",
            "Femmes",
          ]),
      },
    },
  ],
};

const INTRANT_TYPES = {
  engrais: ["kg"],
  herbicide: ["litre"],
  fertilisant: ["kg", "litre"],
  autre: ["kg"],
};

export var INTRANTS_CHARTS = new Array();

Object.entries(INTRANT_TYPES).map((value) => {
  const [intrant_type, units] = value;
  units.map((unit) => {
    const conf = {
      stacked: true,
      primaryChartName: `Quantité ${intrant_type} - ${unit}`,
      legendStart: "",
      params: { intrant__type_intrant: intrant_type, intrant__unit: unit },
      levels: [
        {
          level: 0,
          metaData: {
            label: "departement",
            chartName: "départements",
            endPoint: "intrant_quantites/stats/by_departement/",
            transformer: (jsonData: any) =>
              intrantDataSetFromJson("departement", jsonData, unit),
          },
        },
        {
          level: 1,
          metaData: {
            label: "commune",
            chartName: "communes",
            endPoint: "intrant_quantites/stats/by_commune/",
            transformer: (jsonData: any) =>
              intrantDataSetFromJson("commune", jsonData, unit),
          },
        },
        {
          level: 0,
          metaData: {
            label: "pole",
            chartName: "poles",
            endPoint: "intrant_quantites/stats/by_pole/",
            transformer: (jsonData: any) =>
              intrantDataSetFromJson("pole", jsonData, unit),
          },
        },
      ],
    };
    INTRANTS_CHARTS.push(conf);
  });
});

export const COMPB_CHARTS = [
  {
    primaryChartName: "Beneficiaires HIMO",
    legendStart: "",
    params: { type_amen: "himo" },
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "amenagements/stats/by_departement/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "departement",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "amenagements/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "commune",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "amenagements/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "pole",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
    ],
  },
  {
    primaryChartName: "Beneficiaires HIMO - Entreprise",
    legendStart: "",
    params: { type_amen: "himo_entreprise" },
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "amenagements/stats/by_departement/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "departement",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "amenagements/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "commune",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "amenagements/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "pole",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
    ],
  },
  {
    primaryChartName: "Bénéficiaires Amenagements en Entreprise exclusifs",
    legendStart: "",
    params: { type_amen: "entreprise" },
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "amenagements/stats/by_departement/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "departement",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "amenagements/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson("commune", jsonData, ["benefs_total", "benefs_femmes"]),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "amenagements/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson("pole", jsonData, ["benefs_total", "benefs_femmes"]),
        },
      },
    ],
  },
  {
    primaryChartName: "Benéficiaires retenue d'eau",
    legendStart: "",
    params: { type_amen: "retenue" },
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "amenagements/stats/by_departement/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "departement",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "amenagements/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "commune",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "amenagements/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "pole",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
    ],
  },
  {
    primaryChartName: "Bénéficiaires Pistes",
    legendStart: "",
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "pistes/stats/by_departement/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "departement",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "pistes/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "commune",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "pistes/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "pole",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
    ],
  },
  {
    primaryChartName: "Bénéficiaires Magasins",
    legendStart: "",
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "magasins/stats/by_departement/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "departement",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "magasins/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "commune",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "magasins/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "pole",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
    ],
  },
  {
    primaryChartName: "Bénéficiaires Boutiques",
    legendStart: "",
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "boutiques/stats/by_departement/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "departement",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "boutiques/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "commune",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "boutiques/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "pole",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
    ],
  },
  {
    primaryChartName: "Bénéficiaires ESOP",
    legendStart: "",
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "esop/stats/by_departement/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "departement",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "esop/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "commune",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "esop/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "pole",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
    ],
  },
];

export const GROUPE_CONTACT_CHARTS = [
  {
    primaryChartName: "Membres groupes de contact",
    legendStart: "",
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "producteurs/stats/by_departement/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "departement",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "producteurs/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "commune",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "producteurs/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "pole",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
    ],
  },
  {
    primaryChartName: "Type de champs",
    legendStart: "",
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "producteurs/stats/by_departement/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "departement",
              jsonData,
              ["ud", "cep"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["CEP", "UD"],
            ),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "producteurs/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "commune",
              jsonData,
              ["ud", "cep"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["CEP", "UD"],
            ),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "producteurs/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "pole",
              jsonData,
              ["ud", "cep"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["CEP", "UD"],
            ),
        },
      },
    ],
  },
  {
    primaryChartName: "Rendement",
    legendStart: "",
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "ud_cep/stats/by_departement/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "departement",
              jsonData,
              ["rendement_ud", "rendement_cep"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["UD", "CEP"],
            ),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "ud_cep/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "commune",
              jsonData,
              ["rendement_ud", "rendement_cep"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["UD", "CEP"],
            ),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "ud_cep/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "pole",
              jsonData,
              ["rendement_ud", "rendement_cep"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["UD", "CEP"],
            ),
        },
      },
    ],
  },
];

export const SEMENCIERS_CHARTS = [
  {
    primaryChartName: "Semenciers",
    legendStart: "",
    levels: [
      {
        level: 0,
        metaData: {
          label: "departement",
          chartName: "départements",
          endPoint: "semenciers/stats/by_departement/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "departement",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 1,
        metaData: {
          label: "commune",
          chartName: "communes",
          endPoint: "semenciers/stats/by_commune/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "commune",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
      {
        level: 0,
        metaData: {
          label: "pole",
          chartName: "poles",
          endPoint: "semenciers/stats/by_pole/",
          transformer: (jsonData: any) =>
            chartDataFromJson(
              "pole",
              jsonData,
              ["benefs_total", "benefs_femmes"],
              ["rgba(50, 255, 200, 0.5)", "rgba(255, 50, 0, 0.5)"],
              ["Total", "Femmes"],
            ),
        },
      },
    ],
  },
];

export const MAX_DATA_LEVEL: number = 1;

export interface ChartDataTypeInterface {
  id: string;
  label: string;
  geojson: object;
  // List of divisions names where the project intervene
  interventionSet: any;
}

/**
 * Benin territory can be divided into departements or poles.
 */
export const TERRITORY_DIVISION_TYPES: ChartDataTypeInterface[] = [
  {
    id: "departement",
    label: "Départements",
    geojson: departements,
    interventionSet: ["Alibori", "Borgou", "Collines"],
  },
  {
    id: "pole",
    label: "Poles",
    geojson: poles,
    interventionSet: ["PDA1", "PDA2", "PDA4"],
  },
];

// Mapping
const weightStyle = { weight: 2 };
export const Layers = [
  {
    name: "Départements",
    layer: departements,
    style: weightStyle,
  },
  {
    name: "Départements d'intervention",
    layer: departementsIntervention,
    style: weightStyle,
  },
  {
    name: "Communes",
    layer: benin,
    style: { color: "green", border: "1px", ...weightStyle },
  },
  {
    name: "Communes d'intervention",
    layer: communes,
    style: { color: "red", weight: 0.5 },
  },
  {
    name: "Pôles",
    layer: poles,
    style: { color: "gray", ...weightStyle },
  },
];
export const TABLES = [{}];

const ICON_MARKER_AMENAGEMENT = L.divIcon({
  className: "custom-div-icon",
  html: "<div style='background-color:#c30b82;' class='marker-pin'></div><i class='material-icons'><i class='fa-solid fa-tree'></i></i>",
  iconSize: [30, 42],
  iconAnchor: [15, 42],
});

const ICON_MARKER_RETENU_EAU = L.divIcon({
  className: "custom-div-icon",
  html: "<div style='background-color:#ff8a80;' class='marker-pin'></div><i class='material-icons'><i class='fa-solid fa-water'></i></i>",
  iconSize: [30, 42],
  iconAnchor: [15, 42],
});

const ICON_MARKER_BOUTIQUE = L.divIcon({
  className: "custom-div-icon",
  html: "<div style='background-color:#9b0000;' class='marker-pin'></div><i class='material-icons'><i class='fa-solid fa-shop'></i></i>",
  iconSize: [30, 42],
  iconAnchor: [15, 42],
});

const ICON_MARKER_MAGASIN = L.divIcon({
  className: "custom-div-icon",
  html: "<div style='background-color:#6200ea;' class='marker-pin'></div><i class='material-icons'><i class='fa-solid fa-store'></i></i>",
  iconSize: [30, 42],
  iconAnchor: [15, 42],
});

export interface LayerControlInterface {
  id: string;
  endPoint: string;
  label: string;
  iconMarker: any;
}

export const LAYER_CONTROLS: LayerControlInterface[] = [
  {
    id: "1",
    endPoint: "/amenagements",
    label: "Amenagement",
    iconMarker: ICON_MARKER_AMENAGEMENT,
  },
  {
    id: "2",
    endPoint: "/retenue",
    label: "Magasins",
    iconMarker: ICON_MARKER_MAGASIN,
  },
  {
    id: "3",
    endPoint: "/retenue",
    label: "Retenues d'eau",
    iconMarker: ICON_MARKER_RETENU_EAU,
  },
  {
    id: "4",
    endPoint: "/retenue",
    label: "Boutique",
    iconMarker: ICON_MARKER_BOUTIQUE,
  },
];

export const MAP_FEATURE_TYPES = [
  {
    id: "1",
    correspondingTypes: ["HIMO", "Aménagement en entreprise"],
  },
  {
    id: "2",
    correspondingTypes: ["MAGASINS"],
  },
  {
    id: "3",
    correspondingTypes: ["Retenue d'eau"],
  },
  {
    id: "4",
    correspondingTypes: ["Boutique"],
  },
];

export const REPORT_LIST = [
  new Report({
    intitule: "Rapport1",
    fichier: "fick.pdf",
    auteur: "you",
    resume: "Ressss",
    createdAt: "2021/01/05",
  }),
];
