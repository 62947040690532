import React, { useEffect, useState } from "react";
import Table from "../Core/Table";
import actors from "../../data/actors.json";
import aggregators from "../../data/aggregators.json";
import enterprises from "../../data/enterprises.json";
import contacts from "../../data/contacts_enterprises.json";

function customHeadRender(columnMeta, updateDirection) {
  return (
    <th onClick={() => updateDirection(2)} style={{ cursor: "pointer" }}>
      {columnMeta.name}
    </th>
  );
}

function ActorView() {
  // const [investissements, setInvestissements] = useState(null);
  const [invests, setInvests] = useState(null);
  const [aggContribs, setAggContribs] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [companiesContacts, setCompaniesContacts] = useState(null);

  const columns = [
    {
      label: "Nom",
      name: "Nom",
      options: {
        filter: true,
        customHeadRender,
      },
    },
    {
      label: "Structure",
      name: "Structure",
      options: {
        filter: true,
        customHeadRender,
      },
    },
    {
      label: "Contact",
      name: "Contact",
      options: {
        filter: true,
        customHeadRender,
      },
    },
    {
      label: "Email",
      name: "Email",
      options: {
        filter: true,
        customHeadRender,
      },
    },
  ];

  const companyColumns = [
    {
      label: "Nom",
      name: "Nom",
      options: {
        filter: true,
        customHeadRender,
      },
    },
    {
      label: "Lots",
      name: "Lots",
      options: {
        filter: true,
        customHeadRender,
      },
    },
    {
      label: "Adresse",
      name: "Adresse",
      options: {
        filter: true,
        customHeadRender,
      },
    },
    {
      label: "Contact",
      name: "Contact",
      options: {
        filter: true,
        customHeadRender,
      },
    },
    {
      label: "Email",
      name: "Email",
      options: {
        filter: true,
        customHeadRender,
      },
    },
    {
      label: "Rôle",
      name: "Rôle",
      options: {
        filter: true,
        customHeadRender,
      },
    },
  ];

  useEffect(() => {
    setInvests(
      actors.map((actor) => [
        <div className="flex"> {actor.nom} </div>,
        <div className="flex"> {actor.structure} </div>,
        <div className="flex"> {actor.contact} </div>,
        <div className="flex"> {actor.email} </div>,
      ]),
    );

    setAggContribs(
      aggregators.map((actor) => [
        <div className="flex"> {actor.nom} </div>,
        <div className="flex"> {actor.structure} </div>,
        <div className="flex"> {actor.contact} </div>,
        <div className="flex"> {actor.email} </div>,
      ]),
    );

    setCompanies(
      enterprises.map((company) => [
        <div className="flex"> {company.nom} </div>,
        <div className="flex"> {company.lots} </div>,
        <div className="flex"> {company.addresse} </div>,
        <div className="flex"> {company.contact} </div>,
        <div className="flex"> {company.email} </div>,
        <div className="flex"> {company.category} </div>,
      ]),
    );

    setCompaniesContacts(
      contacts.map((actor) => [
        <div className="flex"> {actor.nom} </div>,
        <div className="flex"> {actor.structure} </div>,
        <div className="flex"> {actor.contact} </div>,
        <div className="flex"> {actor.email} </div>,
      ]),
    );
  }, []);

  return (
    <div className="App px-8 h-screen overflow-scroll">
      <h1 className="font-bold text-xl uppercase my-5">Points focaux</h1>

      <div className="grid grid-cols-1 md:grid-cols-1">
        <Table tableData={invests || null} columns={columns} />
      </div>

      <h1 className="font-bold text-xl uppercase my-5">Aggrégateurs</h1>

      <div className="grid grid-cols-1 md:grid-cols-1">
        <Table tableData={aggContribs || null} columns={columns} fixedHeight={false} />
      </div>

      <h1 className="font-bold text-xl uppercase my-5">Entreprises</h1>

      <div className="grid grid-cols-1 md:grid-cols-1">
        <Table
          tableData={companies || null}
          columns={companyColumns}
          fixedHeight={false}
        />
      </div>

      <h1 className="font-bold text-xl uppercase my-5">Contact Entreprises</h1>

      <div className="grid grid-cols-1 md:grid-cols-1">
        <Table tableData={companiesContacts || null} columns={columns} />
      </div>
    </div>
  );
}

export default ActorView;
