import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Filler,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  BarController,
  CategoryScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { RefreshIcon } from "@heroicons/react/solid";
import { IconButton } from "@mui/material";
import { Tab, Tabs, Box, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { emitCustomEvent } from "react-custom-events";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import RegionSelector from "./RegionSelector";
import "./style.css";
import {
  COMP_A_CHARTS,
  ChartDataTypeInterface as DivisionTypeInterface,
  COMPB_CHARTS,
  TERRITORY_DIVISION_TYPES,
  GROUPE_CONTACT_CHARTS,
  SEMENCIERS_CHARTS,
  INDICATORS_CHARTS,
  INTRANTS_CHARTS,
} from "../../constants";
import BarChart from "./BarChart";
import ChartDataTypeController from "./ChartDataTypeController";
import { Outlet, useOutletContext } from "react-router-dom";
import { useFilters } from "./Dashboard";
import { getFromApiFunction } from "../../api/apiFunctions";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  ArcElement,
  PointElement,
  BarController,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ChartDataLabels,
);

const scrollBarClasses =
  // "scrollbar-thin scrollbar-thumb-slate-500 scrollbar-track-slate-50 overflow-auto";
  "scrollbar scrollbar-thumb-red-500 scrollbar-track-gray-100";
const cardClass =
  "m-2 p-2 text-center bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function ChartRenderer(props: any) {
  /**
   * In the following base code:
   * - divisionType is a specific type of territory division (departments or poles)
   * - division is a specific division of a given type (Ex: Alibori for departments and PDA1 for poles)
   */

  const { composante } = props;

  const { filterParams, selectedDivTypeName } = useFilters();

  const [value, setValue] = React.useState(0);

  const [indics, setIndics]: any = React.useState([]);

  const composantesCharts: any = {
    A: [COMP_A_CHARTS],
    B: [null, COMPB_CHARTS, INTRANTS_CHARTS],
  };

  const composanteSections: any = {
    A: ["Infrastructures"],
    B: ["Indicateurs", "Bénéficiaires", "Intrants"],
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  let theme = createTheme({
    palette: {
      primary: {
        main: "#788c82",
      },
      secondary: {
        main: "#eb70d3",
      },
    },
  });

  useEffect(() => {
    getFromApiFunction("indicators/").then((response) => {
      setIndics(response.data.results);
    });
  }, []);

  useEffect(() => {
    // Re-init tabs index so we always have the first tag active
    // after switching from comp A to comp B
    setValue(0);
  }, [composante]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%" }} className="mt-6 graph-wrapper md:h-full">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
          >
            {composanteSections[composante].map((section: any) => (
              <Tab key={section} label={section} />
            ))}
          </Tabs>
        </Box>
        <Box className="md:overflow-auto" style={{ height: "calc(100% - 49px)" }}>
          {composanteSections[composante].map((section: any, idx: number) => (
            <TabPanel value={value} index={idx} key={idx}>
              <div className={scrollBarClasses}>
                <div className="md:grid lg:grid-cols-2 lg:grid-rows-2 shadow-2xl rounded-md">
                  {TERRITORY_DIVISION_TYPES.map(
                    (divisionType) =>
                      selectedDivTypeName === divisionType.id &&
                      (composante === "A"
                        ? composantesCharts[composante][value] &&
                          composantesCharts[composante][value].map((chart: any) => (
                            <div
                              key={chart.primaryChartName.toString()}
                              className={cardClass}
                            >
                              <BarChart
                                chartProps={chart}
                                initialDivisionType={selectedDivTypeName}
                                filterParams={filterParams}
                              />
                            </div>
                          ))
                        : !value
                        ? indics.map((indic: any) => {
                            const chartProps = {
                              primaryChartName: indic.name,
                              params: ["indicator", indic.id],
                              ...INDICATORS_CHARTS,
                            };
                            if (indic.measure_unit !== "unité") {
                              chartProps.primaryChartName += ` (en ${indic.measure_unit})`;
                            }
                            return (
                              <div key={indic.name} className={cardClass}>
                                <BarChart
                                  chartProps={chartProps}
                                  initialDivisionType={selectedDivTypeName}
                                  filterParams={filterParams}
                                />
                              </div>
                            );
                          })
                        : composantesCharts[composante][value].map((chart: any) => (
                            <div
                              key={chart.primaryChartName.toString()}
                              className={cardClass}
                            >
                              <BarChart
                                chartProps={chart}
                                initialDivisionType={selectedDivTypeName}
                                filterParams={filterParams}
                              />
                            </div>
                          ))),
                  )}
                </div>
              </div>
            </TabPanel>
          ))}
          {/* <TabPanel value={value} index={1} /> */}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
