import anyFileLogo from "./assets/any-file-icon.svg";
import pdfLogo from "./assets/pdf-file-icon.svg";
import msWordLogo from "./assets/docx-file-icon.svg";
import msExcelLogo from "./assets/xls-file-icon.svg";

export const ChooseFileIcon = (docName: string) => {
  const fileNamePart = docName.split(".");
  const fileExtension = fileNamePart[fileNamePart.length - 1];
  switch (fileExtension) {
    case "pdf":
      return pdfLogo;
    case "doc":
    case "docx":
      return msWordLogo;
    case "csv":
    case "xls":
    case "xlsx":
      return msExcelLogo;
    default:
      return anyFileLogo;
  }
};

export const truncateLetters = (str: string, numberOfLetters: number = 30) => {
  if (str.length > numberOfLetters) {
    return `${str.split("").slice(0, numberOfLetters).join("")}...`;
  }
  return str;
};
