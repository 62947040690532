import React from "react";

function NotFoundOutletView() {
  return (
    <div>
      <span className="text-black">PAGE INTROUVABLE OUTLET</span>
    </div>
  );
}
export default NotFoundOutletView;
