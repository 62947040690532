import React, { useState, useEffect } from "react";
import "./CartothequeStyle.css";
import { useTranslation } from "react-i18next";
import { SearchCircleIcon } from "@heroicons/react/solid";
import MapForm from "./MapForm";
import MapZoom from "./MapZoom";
import MapContainer from "./MapContainer";
import AreaMap, { AreaMapConstructorInterface } from "../../models/AreaMapModel";
import { TITLECLASS } from "../../constants";
import { getFromApiFunction } from "../../api/apiFunctions";

function CartothequeView() {
  const { t } = useTranslation();
  const [modalFormOpened, setModalFormOpened] = useState(false);
  const [modalImageOpened, setModalImageOpened] = useState(false);
  const [selectedMap, setSelectedMap] = useState<AreaMap | undefined | null>(null);
  const [maps, setMaps] = useState([]);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setModalFormOpened(modalFormOpened);
  }, [modalFormOpened]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (search) {
      params.append("search", search);
    }
    getFromApiFunction("maps/", params).then((response) => {
      const items = response.data.results;
      setMaps(items.map((item: AreaMapConstructorInterface) => new AreaMap(item)));
    });
  }, [search]);

  const modalFormClosed = () => {
    // Cette fonction permettra de reinitialiser la valuer de modalFormOpened
    // afin de permettre une nouvelle ouverture du modal
    setModalFormOpened(false);
  };

  const modalImageClosed = () => {
    // Cette fonction permettra de reinitialiser la valuer de modalFormOpened
    // afin de permettre une nouvelle ouverture du modal
    setModalImageOpened(false);
  };

  const openImageModal = (map: AreaMap) => {
    setSelectedMap(map);
    setModalImageOpened(true);
  };

  const renderMapContainer = (map: AreaMap) => (
    <MapContainer map={map} openImageModal={openImageModal} />
  );

  return (
    <div className="pt-8 pr-4 pl-4 pb-24 md:p-8">
      <MapZoom
        propsOpen={modalImageOpened}
        onModalClosed={modalImageClosed}
        areaMap={selectedMap}
      />
      <MapForm propsOpen={modalFormOpened} onModalClosed={modalFormClosed} />
      {/* <h1 className={TITLECLASS}>{t("mapLibrary.title")}</h1>
      <hr className="border-solid border-2 border-slate-400 my-2 " /> */}
      <div className="mt-1 flex rounded-full shadow-sm justify-center max-w-xs container mx-auto bg-white">
        <input
          type="text"
          name="search"
          className="w-full flex-1 focus:outline-0 border-transparent focus:border-transparent sm:text-sm bg-white"
          placeholder="Nom de la carte..."
          onChange={(event) => {
            setSearchText(event.target.value);
          }}
        />
        <button
          className="inline-flex items-center rounded-full border border-l-0 border-gray-300 px-3 text-sm text-gray-500 primary-background"
          type="button"
          onClick={() => setSearch(searchText)}
        >
          <SearchCircleIcon className="text-white h-8 w-8 p-1" />
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4">
        {maps.map((map) => renderMapContainer(map))}
      </div>
    </div>
  );
}

export default CartothequeView;
