/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React from "react";
// import { PencilIcon, ArrowSmDownIcon } from "@heroicons/react/solid";
import { DownloadIcon, ArrowSmDownIcon, PencilIcon } from "@heroicons/react/solid";
import { Disclosure, Transition } from "@headlessui/react";
import Report from "../../models/ReportModel";
import { ChooseFileIcon, truncateLetters } from "./ReportHelpers";

// const cardClass = "";

/* eslint-disable no-unused-vars */
interface ComponentProps {
  report: Report;
}
/* eslint-enable no-unused-vars */

function ReportContainer(props: ComponentProps) {
  const { report } = props;

  return (
    <div className="relative m-2 pt-2 text-center bg-white rounded-2xl border shadow-md dark:bg-gray-800 dark:border-gray-700">
      <div className="relative px-4">
        <img
          src={ChooseFileIcon(report.intitule)}
          alt=""
          role="presentation"
          className="object-fill w-full max-h-52"
        />
        <a
          type="button"
          className="p-2 border-2 absolute right-2 rounded-full bg-white map-download-button"
          href={report.fichier}
          target="_blank"
          rel="noreferrer"
        >
          <DownloadIcon className="h-4 w-4 text-black" />
        </a>
      </div>

      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full justify-between primary-background rounded-b-2xl px-4 py-2 text-left text-sm font-medium">
              <span className="font-normal">{truncateLetters(report.resume)}</span>
            </Disclosure.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            />

            <Disclosure.Panel className="text-gray-500 text-left">
              {report.resume}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}

export default ReportContainer;
