/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./SideBar.css";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import LogoutIcon from "@mui/icons-material/Logout";
import linkClass from "./helperfunctions";
import { SIDEBARELEMENTS } from "../../constants";
import { apiLogout } from "../../api/auth/login";

function SideBar(props) {
  // const [open, setOpen] = React.useState(true);

  const navigate = useNavigate();
  const toggleOpenSideBar = () => {
    props.toggleOpenSideBar();
  };

  const logout = () => {
    apiLogout();
    navigate("/");
  };

  const currentLocation = useLocation().pathname;
  const { t } = useTranslation();
  const open = props.open;
  // const toggleOpenSideBar = props.toggleOpenSideBar;

  return (
    <div className="relative">
      <div
        className={`${
          open ? "md:w-48" : "md:w-20"
        } h-screen primary-background py-5 px-3 content-center flex flex-col rounded-tr-3xl shadow-xl`}
      >
        <div
          className={
            open
              ? "mb-4 bg-white w-12 h-12 rounded-full flex justify-start items-center text-center container mx-auto"
              : "mb-4 bg-white w-5 h-5 rounded-full flex justify-start items-center text-center  container mx-auto"
          }
        >
          <img
            src="/logo-rond.jpg"
            alt=""
            className={open ? "h-12 m-auto" : "w-5 h-5 m-auto"}
          />
        </div>
        <div className="grow h-full flex flex-col">
          <div className="grow">
            <hr className="sidebar-divider mb-8"></hr>

            <ul>
              {SIDEBARELEMENTS.map((element) => (
                <li
                  className={`${!open ? "reduce-menu" : ""} flex-1 container mx-auto`}
                  key={element.url}
                >
                  <Link
                    className={linkClass(currentLocation, element.url)}
                    to={element.url}
                  >
                    {open ? <element.leading /> : <element.centeredLeading />}
                    {open ? (
                      <span className="capitalize"> {t(element.title)} </span>
                    ) : null}
                  </Link>
                  <div className={`${open ? "ml-2" : ""}`}>
                    {currentLocation.startsWith(element.url) && (
                      <ul>
                        {element.subElements?.map((subElmt) => (
                          <li
                            className={`${!open ? "reduce-menu" : ""} flex-1`}
                            key={subElmt.url}
                          >
                            <Link
                              className={linkClass(currentLocation, subElmt.url)}
                              to={subElmt.url}
                            >
                              {open ? <subElmt.leading /> : <subElmt.centeredLeading />}
                              {open ? (
                                <span className="capitalize"> {t(subElmt.title)} </span>
                              ) : null}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="">
            <hr className="sidebar-divider mb-8"></hr>
            <Link
              className="font-medium text-white text-tiny hover:bg-blue-100 hover:rounded-full hover:text-black link p-3 container mx-auto"
              to="/login"
              onClick={logout}
            >
              <LogoutIcon />
              {open ? <span className="capitalize"> Deconnexion </span> : null}
            </Link>
          </div>
        </div>
      </div>
      <div className="deb">
        <div className="w-16 h-8 rounded-full primary-background pr-1 text-right content-center">
          <CompareArrowsIcon
            onClick={toggleOpenSideBar}
            style={{ paddingLeft: "0.25rem" }}
            className="h-2 w-2 text-white pl-0"
          />
          {/* <h1 className="text-white">g</h1> */}
        </div>
      </div>
    </div>
  );
}

export default SideBar;
