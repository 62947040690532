import React from "react";

function SettingsView() {
  return (
    <div>
      <h2>Settings page</h2>
    </div>
  );
}

export default SettingsView;
