import React from "react";
import "./CartothequeStyle.css";
import { DownloadIcon } from "@heroicons/react/solid";
import { Disclosure, Transition } from "@headlessui/react";
import AreaMap from "../../models/AreaMapModel";

const cardClass =
  "relative map-container m-2 text-center bg-white rounded-2xl border shadow-md dark:bg-gray-800 dark:border-gray-700";

/* eslint-disable no-unused-vars */
interface ComponentProps {
  map: AreaMap;
  openImageModal: (map: AreaMap) => void;
}
/* eslint-enable no-unused-vars */

function MapContainer(props: ComponentProps) {
  const { map, openImageModal } = props;

  return (
    <div className="relative m-2 pt-2 text-center bg-white rounded-2xl border shadow-md dark:bg-gray-800 dark:border-gray-700">
      <div className="relative px-4">
        <img
          src={map.image}
          alt=""
          role="presentation"
          className="rounded-t-3xl"
          onClick={() => openImageModal(map)}
        />
        <a
          type="button"
          className="p-2 border-2 absolute right-2 rounded-full bg-white map-download-button"
          href={map.image}
          target="_blank"
          rel="noreferrer"
        >
          <DownloadIcon className="h-4 w-4 text-black" />
        </a>
      </div>

      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full justify-between primary-background rounded-b-2xl px-4 py-2 text-left text-sm font-medium">
              <span className="text-white"> Voir la description </span>
            </Disclosure.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            />

            <Disclosure.Panel className="text-gray-500 text-left">
              {map.description}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}

export default MapContainer;
