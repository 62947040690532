import React, { useEffect, useState } from "react";
import { SearchCircleIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import "./ReportListStyle.css";
import Report from "../../models/ReportModel";
import ReportContainer from "./ReportContainer";
import { REPORTS_BASE } from "../../api/ReportServices";
import { REPORT_LIST, TITLECLASS } from "../../constants";
import { getFromApiFunction } from "../../api/apiFunctions";

function ReporListView() {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [reports, setReports] = useState([]);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const params = new URLSearchParams();
    if (search) {
      params.append("search", search);
    }
    getFromApiFunction(REPORTS_BASE, params)
      .then((response) => {
        const apiReports = response.data.results.map((element: any) =>
          Report.fromJson(element),
        );
        setReports(apiReports);
      })
      .catch(() => {
        // Handle error
      });
  }, [search]);

  return (
    <div className="pt-8 pr-4 pl-4 pb-24 md:p-8">
      {/* <h1 className={TITLECLASS}>{t("reportPage.title")}</h1>
      <hr className="border-solid border-2 border-slate-400 my-2 " /> */}
      <div className="mt-1 flex rounded-full shadow-sm justify-center max-w-xs container mx-auto bg-white">
        <input
          type="text"
          name="search"
          className="w-full flex-1 focus:outline-0 border-transparent focus:border-transparent sm:text-sm bg-white"
          placeholder="Nom du document, nom de l'auteur..."
          onChange={(event) => {
            setSearchText(event.target.value);
          }}
        />
        <button
          className="inline-flex items-center rounded-full border border-l-0 border-gray-300 px-3 text-sm text-gray-500 primary-background"
          type="button"
          onClick={() => setSearch(searchText)}
        >
          <SearchCircleIcon className="text-white h-8 w-8 p-1" />
        </button>
      </div>

      <div className="mt-4">
        <div className="grid grid-cols-none md:grid-cols-5 justify-center md-justify-start">
          {reports.map((report: any) => (
            <ReportContainer report={report} key={report.intitule} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ReporListView;
